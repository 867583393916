<template>
  <div>
    <nav id="navigation" class="navbar navbar-expand-lg navbar-light">
      <div class="container full p-lg-0">
        <router-link class="navbar-brand mr-auto" to="/">
          <LogoSvg />
        </router-link>
        <div class="w-40">
          <router-link class="navbar-brand mr-auto text-center" to="/">
            <DriveLogoSvg />
            <br />
            <span class="d-sm-inline">FİKİR PLATFORMU</span>
          </router-link>
        </div>  
        <button
          @click="isNavbarToggler = !isNavbarToggler"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse w-100">
          <div
            id="search"
            class="search navbar-nav w-100 justify-content-center"
          >
            <input
              id="txtSearch"
              type="text"
              placeholder="Fikir ara..."
              v-model="searchText"
            />
            <router-link
              class="search-idea-button"
              style="position: relative; right: 10%"
              :to="{ path: '/search', query: { searchText: searchText } }"
            >
              <img
                src="../../assets/images/search.png"
                style="width: 130%; vertical-align: middle; margin-top: 100%"
                alt="Inovasyon Platformu"
              />
            </router-link>
          </div>

          <ul
            class="nav navbar-nav ml-auto w-100 align-items-center justify-content-end"
          >
            <li class="nav-item font-weight-bold">
              <router-link class="nav-link" to="/FrequentlyAskedQuestion">
                SSS
              </router-link>
            </li>
            <li class="nav-item font-weight-bold">
              <router-link class="nav-link" to="/suggestion/idea">
                Fikirlerim
              </router-link>
            </li>
            <b-dropdown
              style="margin-left: 10px"
              id="navbarDropdown"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template slot="button-content">
                <span
                  class="font-weight-bold"
                  :title="user ? user.Data.FullName : ''"
                >
                  {{ user ? user.Data.FullName : "" }}
                </span>
                <img
                  v-if="user.Data.UserProfileImage"
                  class="user-image fallback-image"
                  :src="
                    user.Data.UserProfileImage
                      ? `data:image/png;base64, ${user.Data.UserProfileImage}`
                      : ''
                  "
                  :alt="user.Data.FullName | getInitialsFilter"
                />
                <i
                  v-else
                  class="user-image fallback-image"
                  :alt="user.Data.FullName | getInitialsFilter"
                  >{{ user.Data.FullName | getInitialsFilter }}</i
                >
              </template>
              <b-dropdown-item
                v-if="user.Data.IsVisibleAdminPanel"
                @click="$router.push('/admin/suggestions')"
                >Değerlendirme Ekranı</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="logout">Çıkış Yap</b-dropdown-item>
            </b-dropdown>
          </ul>
        </div>
      </div>
    </nav>
    <transition name="slide-down">
      <div
        v-if="isNavbarToggler"
        class="navbar container mw-100 navbar-collapse collapse d-lg-none pb-3"
        :class="{ show: isNavbarToggler }"
        id="navbarToggler"
      >
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item active">
            <router-link class="nav-link" to="/FrequentlyAskedQuestion">
              Sıkça Sorulan Sorular
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/suggestion/idea">
              Fikirlerim
            </router-link>
          </li>
          <b-dropdown
            id="navbarDropdown"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template slot="button-content">
              <span :title="user ? user.Data.FullName : ''">
                {{ user ? user.Data.FullName : "" }}
              </span>
              <img
                v-if="user.Data.UserProfileImage"
                class="article-user-img fallback-image"
                :src="
                  user.Data.UserProfileImage
                    ? `data:image/png;base64, ${user.Data.UserProfileImage}`
                    : ''
                "
                :alt="user.Data.FullName | getInitialsFilter"
              />
              <i
                v-else
                class="user-image fallback-image"
                :alt="user.Data.FullName | getInitialsFilter"
                >{{ user.Data.FullName | getInitialsFilter }}</i
              >
            </template>
            <b-dropdown-item
              v-if="user.Data.IsVisibleAdminPanel"
              @click="$router.push('/admin/suggestions')"
              >Değerlendirme Ekranı</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="logout">Çıkış Yap</b-dropdown-item>
          </b-dropdown>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LogoSvg from "../Main/LogoSvg.vue";
import DriveLogoSvg from "../Main/DriveLogoSvg.vue";
import { getInitials } from "../../helpers";
export default {
  name: "MainHeader",
  components: {
    LogoSvg,
    DriveLogoSvg,
  },
  data() {
    return {
      isNavbarToggler: false,
      searchText: "",
    };
  },
  filters: {
    getInitialsFilter(value) {
      return getInitials(value);
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  methods: {
    delete_cookie(name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    async logout() {
      localStorage.clear();
      this.delete_cookie("token");
      await this.$msalInstance
        .logoutRedirect({})
        .then(() => {})
        .catch(() => {});
      location.reload();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/scss/header.scss";
</style>
