import { suggestionService, suggestionRdService, upload } from "../../services";
import { updateDate, userStatus } from "../../helpers";
import store from "../store";
export const ideas = {
  namespaced: true,
  state: {
    data: [],
    totalRecords: null,
    dataProduct: [],
    productTotalRecords: null,
    dataInvendo: [],
    invendoTotalRecords: null,
    ideaData: [],
    ideaTotalRecords: null,
    ideaProductData: [],
    ideaProductTotalRecords: null,
    filterIdea: null,
    teamLead: [],
    iteamLeadTotalRecords: null,
    dataMyAss: [],
    myAssTotalRecords: null,
  },
  getters: {
    getData(state) {
      return state.data;
    },
    totalRecords(state) {
      return state.totalRecords;
    },
    getInvendoData(state) {
      return state.dataInvendo;
    },
    invendoTotalRecords(state) {
      return state.invendoTotalRecords;
    },
    getProductData(state) {
      return state.dataProduct;
    },
    productTotalRecords(state) {
      return state.productTotalRecords;
    },
    getIdeaData(state) {
      return state.ideaData;
    },
    ideaTotalRecords(state) {
      return state.ideaTotalRecords;
    },
    getIdeaProductData(state) {
      return state.ideaProductData;
    },
    ideaProductTotalRecords(state) {
      return state.ideaProductTotalRecords;
    },
    getFilterIdea(state) {
      return state.filterIdea;
    },
    getTeamLeadData(state) {
      return state.teamLead;
    },
    teamLeadTotalRecords(state) {
      return state.teamLeadTotalRecords;
    },
    getMyAssData(state) {
      return state.dataMyAss;
    },
    myAssTotalRecords(state) {
      return state.myAssTotalRecords;
    },
  },
  mutations: {
    async SET_DATA(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.data.push(...payload);
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
    },
    DELETE_DATA(state) {
      state.data = [];
    },
    async SET_PRODUCT_DATA(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.dataProduct.push(...payload);
    },
    SET_PRODUCT_TOTAL_RECORDS(state, payload) {
      state.productTotalRecords = payload;
    },
    DELETE_PRODUCT_DATA(state) {
      state.dataProduct = [];
    },
    async SET_INVENDO_DATA(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.dataInvendo.push(...payload);
    },
    SET_INVENDO_TOTAL_RECORDS(state, payload) {
      state.invendoTotalRecords = payload;
    },
    DELETE_INVENDO_DATA(state) {
      state.dataInvendo = [];
    },
    async SET_IDEA_DATA(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.ideaData.push(...payload);
    },
    SET_IDEA_TOTAL_RECORDS(state, payload) {
      state.ideaTotalRecords = payload;
    },
    DELETE_IDEA_DATA(state) {
      state.ideaData = [];
    },
    async SET_IDEA_PRODUCT_DATA(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.ideaProductData.push(...payload);
    },
    SET_IDEA_PRODUCT_TOTAL_RECORDS(state, payload) {
      state.ideaProductTotalRecords = payload;
    },
    DELETE_IDEA_PRODUCT_DATA(state) {
      state.ideaProductData = [];
    },
    SET_FILTER_IDEA(state, payload) {
      state.filterIdea = payload;
    },
    async SET_DATA_TEAM_LEAD(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.teamLead.push(...payload);
    },
    SET_TOTAL_RECORDS_TEAM_LEAD(state, payload) {
      state.teamLeadTotalRecords = payload;
    },
    DELETE_DATA_TEAM_LEAD(state) {
      state.teamLead = [];
    },
    async SET_MYASS_DATA(state, payload) {
      payload.forEach(async (element) => {
        element.MyAssignment = "";
        let _tmpMyAssignment = [];
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
        if (element.SuggestionRDUserModels.length > 0) {
          element.SuggestionRDUserModels.forEach((role) => {
            if (role.UserId == store.getters["user/user"].Data.UserId) {
              _tmpMyAssignment.push(userStatus(role.UserStatus));
              element.MyAssignment = _tmpMyAssignment.join();
            }
          });
        } else {
          element.MyAssignment = "";
        }
      });
      state.dataMyAss.push(...payload);
    },
    SET_MYASS_TOTAL_RECORDS(state, payload) {
      state.myAssTotalRecords = payload;
    },
    DELETE_MYASS_DATA(state) {
      state.dataMyAss = [];
    },
  },
  actions: {
    async setData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        companyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        loginUserId,
        suggestionEvaluatorId,
        teamLeaderId,
        department
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        CompanyId: companyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        LoginUserId: loginUserId,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        TeamLeaderId : teamLeaderId,
        Department: department
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("DELETE_DATA");
      res.Data && vuexContext.commit("SET_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    deleteData(vuexContext) {
      vuexContext.commit("DELETE_DATA");
    },
    async setInvendoData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        companyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        loginUserId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        CompanyId: companyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        LoginUserId: loginUserId,
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("DELETE_INVENDO_DATA");
      res.Data && vuexContext.commit("SET_INVENDO_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_INVENDO_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    deleteInvendoData(vuexContext) {
      vuexContext.commit("DELETE_INVENDO_DATA");
    },
    async setProductData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        suggestionTypes,
        companyId,
        groupCompanyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        loginUserId,
        startDate,
        endDate,
        suggestionEvaluatorId,
        suggestionDeciderId,
        department,
        valueCurrentManagerId,
        title,
        projectYear,
        suggestionTeamLeaderId,
        teamLeaderId,
      } = data;
      const body = {
        Page: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        SuggestionTypes: suggestionTypes,
        CompanyId: companyId,
        CompanyGroupId: groupCompanyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
        StartDate: startDate,
        EndDate: endDate,
        IsAssignment: false,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        SuggestionDeciderId: suggestionDeciderId,
        Department: department,
        ValueCurrentManagerId: valueCurrentManagerId,
        Title: title,
        ProjectYear: projectYear,
        SuggestionTeamLeaderId: suggestionTeamLeaderId,
        TeamLeaderId: teamLeaderId,
      };
      const res = await suggestionRdService.fetchPagedSuggesitonRD(body);
      res.Data && vuexContext.commit("DELETE_PRODUCT_DATA");
      res.Data && vuexContext.commit("SET_PRODUCT_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_PRODUCT_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    deleteProductData(vuexContext) {
      vuexContext.commit("DELETE_PRODUCT_DATA");
    },
    async setIdeaData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        companyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        loginUserId,
        startDate,
        endDate,
        suggestionEvaluatorId,
        teamLeaderId,
        department
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        CompanyId: companyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
        StartDate: startDate,
        EndDate: endDate,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        TeamLeaderId : teamLeaderId,
        Department: department
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("DELETE_IDEA_DATA");
      res.Data && vuexContext.commit("SET_IDEA_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_IDEA_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    deleteIdeaData(vuexContext) {
      vuexContext.commit("DELETE_IDEA_DATA");
    },
    async setIdeaProductData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        suggestionTypes,
        companyId,
        groupCompanyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        loginUserId,
        startDate,
        endDate,
        suggestionEvaluatorId,
        suggestionDeciderId,
        department,
        valueCurrentManagerId,
        title,
        projectYear,
        suggestionTeamLeaderId,
        teamLeaderId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        SuggestionTypes: suggestionTypes,
        CompanyId: companyId,
        CompanyGroupId: groupCompanyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
        StartDate: startDate,
        EndDate: endDate,
        IsAssignment: false,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        SuggestionDeciderId: suggestionDeciderId,
        Department: department,
        ValueCurrentManagerId: valueCurrentManagerId,
        Title: title,
        ProjectYear: projectYear,
        SuggestionTeamLeaderId: suggestionTeamLeaderId,
        TeamLeaderId: teamLeaderId,
      };
      const res = await suggestionRdService.fetchPagedSuggesitonRD(body);
      res.Data && vuexContext.commit("DELETE_IDEA_PRODUCT_DATA");
      res.Data && vuexContext.commit("SET_IDEA_PRODUCT_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_IDEA_PRODUCT_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    deleteIdeaProductData(vuexContext) {
      vuexContext.commit("DELETE_IDEA_PRODUCT_DATA");
    },
    setFilterIdea(vuexContext, data) {
      vuexContext.commit("SET_FILTER_IDEA", data);
    },
    async setDataTeamLead(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        suggestionTypes,
        companyId,
        groupCompanyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        suggestionEvaluatorId,
        suggestionDeciderId,
        department,
        valueCurrentManagerId,
        title,
        projectYear,
        suggestionTeamLeaderId,
        teamLeaderId,
        loginUserId,
        isAssignToTeamLeader,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        SuggestionTypes: suggestionTypes,
        CompanyId: companyId,
        GroupCompanyId: groupCompanyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        SuggestionDeciderId: suggestionDeciderId,
        Department: department,
        ValueCurrentManagerId: valueCurrentManagerId,
        Title: title,
        ProjectYear: projectYear,
        SuggestionTeamLeaderId: suggestionTeamLeaderId,
        TeamLeaderId: teamLeaderId,
        LoginUserId: loginUserId,
        IsAssignToTeamLeader: isAssignToTeamLeader,
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("DELETE_DATA_TEAM_LEAD");
      res.Data && vuexContext.commit("SET_DATA_TEAM_LEAD", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_TOTAL_RECORDS_TEAM_LEAD",
          res.Data.TotalRecords
        );
    },
    deleteDataTeamlead(vuexContext) {
      vuexContext.commit("DELETE_DATA_TEAM_LEAD");
    },
    async setMyAssignmentsData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        suggestionTypes,
        companyId,
        groupCompanyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        suggestionEvaluatorId,
        suggestionDeciderId,
        department,
        valueCurrentManagerId,
        title,
        projectYear,
        suggestionTeamLeaderId,
        teamLeaderId,
        isAssignment,
        loginUserId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        SuggestionTypes: suggestionTypes,
        CompanyId: companyId,
        CompanyGroupId: groupCompanyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        SuggestionDeciderId: suggestionDeciderId,
        Department: department,
        ValueCurrentManagerId: valueCurrentManagerId,
        Title: title,
        ProjectYear: projectYear,
        SuggestionTeamLeaderId: suggestionTeamLeaderId,
        TeamLeaderId: teamLeaderId,
        IsAssignment: isAssignment,
        LoginUserId: loginUserId,
      };
      const res = await suggestionRdService.fetchPagedSuggesitonRD(body);
      res.Data && vuexContext.commit("DELETE_MYASS_DATA");
      res.Data && vuexContext.commit("SET_MYASS_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_MYASS_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    deleteMyAssignmentData(vuexContext) {
      vuexContext.commit("DELETE_MYASS_DATA");
    },
  },
};
