<template>
  <div>
    <form
      action="/Admin/UpdateProjectProgress"
      id="updateProjectProgressForm"
      method="post"
    >
      <input
        name="__RequestVerificationToken"
        type="hidden"
        value="9FJi9UkNpP-BBjAaTsYuMxB1fJLb3gBWQ3KPeQBwHEeL1ehecJhr0SGYee_8_V4SZkzLfTEsQWoSp5y6dz1-DMVdpwl7XNJbQx1MfTYzeqB8Js31zZ_8S4u_dL4rwK2B8NGyqYISMh-j4XfowdnbXA2"
      />
      <dl class="row px-3 px-sm-5 pt-3 mb-0">
        <dt>Proje Gelişim Durumu</dt>
        <dd class="single">
          <div class="form-group">
            <input
              data-val="true"
              data-val-required="The Id field is required."
              id="Id"
              name="Id"
              type="hidden"
              value="718d7c1f-7dec-eb11-bacb-000d3ade7034"
            />
            <textarea
              class="form-control short"
              cols="20"
              id="ProjectProgress"
              maxlenght="3000"
              name="ProjectProgress"
              placeholder="Proje gelişim durumu..."
              rows="2"
              v-model="projectProgress"
            ></textarea>
          </div>
          <div class="form-group float-right">
            <button
              type="button"
              @click="saveProjectProgress"
              class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
            >
              KAYDET
            </button>
          </div>
        </dd>
      </dl>
    </form>
    <dl class="row px-3 px-sm-5 pt-3 mb-0">
      <dt class="no-colon d-print-none w-xs-100">Aksiyon Seçiniz</dt>
      <dd class="single d-print-none w-xs-100 pl-1 pl-sm-0">
        <form id="updateSuggestionResultForm">
          <input
            name="__RequestVerificationToken"
            type="hidden"
            value="VBrI4HoiLXlnxE0_6rh2XqoVmlmwWYWKyPlQdVW-THoJxa40c7UcJv4nZZGeC8TmRAoXc1M-kVEQfQHpaDX8Y1RKIRmlIq3NVhstmPTTNKeTK_l9STrVxt7Wxm5GPn79EoPf-brb-TnE_gq9ps4R8A2"
          />
          <input
            data-val="true"
            data-val-required="The Id field is required."
            id="Id"
            name="Id"
            type="hidden"
            value="b6574625-3b32-ec11-b6e6-000d3adab7a4"
          />
          <div
            v-if="
              detailData.SuggestionStatusId != '8' &&
              detailData.SuggestionStatusId != '10'
            "
            class="form-group icon-down"
          >
            <select
              class="form-control"
              id="SuggestionApplicationStatus"
              name="SuggestionApplicationStatus"
              validationelement="1"
              v-model="suggestionApplicationStatus"
            >
              <option value="">Seçiniz...</option>
              <option value="8">Çalışma hayata geçti</option>
              <option value="9">Beklemede</option>
              <option value="10">Çalışma İptal Edildi</option>
            </select>
          </div>
          <div v-else>{{ detailData.SuggestionStatusName }}</div>
          <div
            id="SuggestionApplicationStatusError"
            class="form-group invalid-feedback"
            style="display: none"
          >
            Bu alan boş geçilemez!
          </div>
          <div
            id="ProjectEndDatePanel"
            v-if="suggestionApplicationStatus == '8'"
          >
            <div class="input-group date">
              <input
                class="datepicker form-control"
                data-val="true"
                data-val-date="The field ProjectEndDate must be a date."
                id="ProjectEndDate"
                name="ProjectEndDate"
                placeholder="Proje bitiş tarihi"
                type="date"
                v-model="projectEndDate"
              />
              <span class="input-group-addon"
                ><i class="glyphicon glyphicon-th"></i
              ></span>
            </div>
            <div
              id="ProjectEndDateError"
              class="form-group invalid-feedback"
              style="margin-top: 15px"
              :style="[
                requiredField.includes('projectEndDate')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
          </div>
          <div
            class="form-group"
            id="ProjectCancelledReasonPanel"
            v-if="suggestionApplicationStatus == '10'"
          >
            <textarea
              class="form-control short"
              cols="20"
              id="ProjectCancelledReason"
              maxlenght="2000"
              name="ProjectCancelledReason"
              placeholder="Proje iptal nedeni..."
              rows="2"
              v-model="projectCancelledReason"
            ></textarea>
          </div>
          <div
            id="ProjectCancelledReasonError"
            class="form-group invalid-feedback"
            :style="[
              requiredField.includes('projectCancelledReason')
                ? { display: 'block' }
                : '',
            ]"
          >
            Bu alan boş geçilemez!
          </div>
          <div
            class="form-group"
            id="ProjectPendingReasonPanel"
            v-if="suggestionApplicationStatus == '9'"
          >
            <textarea
              class="form-control short"
              cols="20"
              id="ProjectPendingReason"
              maxlenght="2000"
              name="ProjectPendingReason"
              placeholder="Proje bekleme nedeni..."
              rows="2"
              v-model="projectPendingReason"
            ></textarea>
          </div>
          <div
            id="ProjectPendingReasonError"
            class="form-group invalid-feedback"
            :style="[
              requiredField.includes('projectPendingReason')
                ? { display: 'block' }
                : '',
            ]"
          >
            Bu alan boş geçilemez!
          </div>

          <div class="mt-2">
            <!-- <div class="form-group">
              <textarea
                class="form-control short"
                cols="20"
                id="AboutExpectedBenefit"
                name="AboutExpectedBenefit"
                placeholder="Fayda (TL - adam saat/yıl)"
                rows="2"
                v-model="aboutExpectedBenefit"
              ></textarea>
            </div> -->
            <!-- <div class="form-group">
              <input
                class="form-control short"
                id="ApplicationCost"
                name="ApplicationCost"
                placeholder="Uygulama Maliyeti"
                style="padding-left: 20px !important"
                type="text"
                v-model="applicationCost"
              />
            </div> -->

            <div class="form-group">
              <div
                class="input-group mb-3"
                v-for="(item, key) in fileUploadData"
                :key="key"
              >
                <div class="input-group-prepend">
                  <span
                    class="input-group-text icon-folder-alt"
                    id="basic-addon1"
                  ></span>
                </div>
                <p
                  class="form-control d-flex align-items-center cursor-default"
                  style="border: 0px"
                >
                  <input
                    type="file"
                    class="custom-file-input"
                    id="customFileArge"
                    name="customFile"
                    ref="fileUploadInput"
                    multiple
                    :accept="supportedFileType"
                    @change="fileUploadChange"
                  />
                  <label
                    class="custom-file-label hide-overflow-text selected"
                    for="customFile"
                    id="lblDocument"
                  >
                    {{ item.Filename
                    }}{{ item.Extension ? `.${item.Extension}` : "" }}
                  </label>
                </p>
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-danger fas fa-trash-alt"
                    :disabled="fileId?.length == 0"
                    @click="deleteDocument(item.Id)"
                  ></button>
                </div>
              </div>
              <small
                class="Raleway-Medium"
                :style="[isFileMB ? { color: 'red' } : '']"
                >*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.</small
              >
              <br />
              <small class="Raleway-Medium"
                >**Birden fazla doküman yüklemek için sıkıştırılmış dosya (.zip
                vb.) kullanabilirsiniz.</small
              >
            </div>
            <div
              class="form-group custom-file icon-folder-alt"
              v-if="detailData && downloadFile?.length > 0"
            >
              <p
                class="form-control d-flex align-items-center cursor-default"
                v-for="item in downloadFile"
                :key="item.Id"
              >
                <span
                  class="flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                  >{{ item.Filename }}.{{ item.Extension }}</span
                >
                <a
                  :href="
                    'data:' + item.fileType + ';base64,' + item.DownloadBinary
                  "
                  :download="item.Filename + '.' + item.Extension"
                  class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                  title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                  alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                  target="_blank"
                >
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-light text-danger ml-3 fas fa-trash-alt"
                  id="document-remove"
                  title="Dokümanı silmek için tıklayın."
                  alt="Dokümanı silmek için tıklayın."
                  @click="deleteDocument(item.Id)"
                ></a>
              </p>
            </div>
            <div
              class="form-group custom-file icon-folder-alt mt-2"
              style="display: none"
              id="fileUploaded"
            >
              <p class="form-control d-flex align-items-center cursor-default">
                <span
                  class="flex-shrink-1 ml-md-5 hide-overflow-text cursor-default"
                  id="uploadedFileName"
                ></span>
                <a
                  href="javascript:void(0)"
                  class="btn btn-light text-danger ml-5 fas fa-trash-alt"
                  id="document-remove"
                  title="Dokümanı silmek için tıklayın."
                  alt="Dokümanı silmek için tıklayın."
                  onclick="DeleteFile('81dcd29a-111e-ec11-b6e6-000d3adecfeb')"
                ></a>
              </p>
            </div>
          </div>

          <div class="form-group float-right mt-5">
            <button
              id="SuggestionResultSubmit"
              type="button"
              class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
              :class="{ 'disabled-btn': suggestionApplicationStatus == '' }"
              @click="suggestionResultSubmit"
              :disabled="suggestionApplicationStatus == '' || isBtnDisabled"
            >
              GÖNDER
            </button>
          </div>
        </form>
      </dd>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { upload } from "../../../services";
import {
  bytesToMegaBytes,
  toBase64,
  fileTypeCheck,
  isNil,
  currentDateTime,
} from "../../../helpers";
export default {
  name: "Aapproved",
  data() {
    return {
      projectProgress: "",
      suggestionApplicationStatus: "",
      projectEndDate: null,
      projectCancelledReason: null,
      projectPendingReason: null,
      requiredField: [],
      isBtnDisabled: false,
      // aboutExpectedBenefit: null,
      // applicationCost: null,
      isFileMB: false,
      fileId: [],
      fileUploadData: [
        {
          Extension: "",
          Filename: "Doküman Yükle +",
          Id: 0,
        },
      ],
      downloadFile: [],
      supportedFileType:
        "image/jpeg,image/jpg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/zip,application/pdf",
    };
  },
  props: ["detailData"],
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  created() {
    this.projectProgress = this.detailData.ProjectProgress;
    this.downloadFile = this.detailData.EvaluatorDownloadFiles;
    this.suggestionApplicationStatus = this.detailData.SuggestionStatusId;
    this.projectCancelledReason = this.detailData.ProjectCancelledReason;
    this.projectPendingReason = this.detailData.ProjectPendingReason;
    // this.aboutExpectedBenefit = this.detailData.AboutExpectedBenefit;
    // this.applicationCost = this.detailData.ApplicationCost;
  },
  methods: {
    getCurrentDateTime() {
      return currentDateTime();
    },    
    saveProjectProgress() {
      this.$emit("save-project-progress", this.projectProgress);
    },
    async fileUploadChange(event) {
      this.fileUploadData = [];
      const files = [...event.srcElement.files];
      files.forEach(async (file, index) => {
        if (Math.round(bytesToMegaBytes(file.size)) <= 40) {
          const fileName = file.name.substring(0, file.name.lastIndexOf("."));
          const docExtension = file.name.substring(
            file.name.lastIndexOf(".") + 1,
            file.name.length
          );
          const base64 = await toBase64(file);
          const splitBase64 = await base64.split(",")[1];
          const body = {
            Filename: fileName,
            Extension: docExtension,
            DownloadBinary: splitBase64,
            ContentType: fileTypeCheck(docExtension),
          };

          try {
            this.$store.dispatch("loading/setIsLoading", true);
            const res = await upload.fetchUpluadFile(body);
            this.isFileMB = false;
            this.fileId.push(res.Data.Id);
            this.fileUploadData.push(res.Data);
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.success("Dosya başarıyla yüklendi.", {
              position: "top",
            });
          } catch (error) {
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
              position: "top",
            });
            this.deleteDocument(this.fileId[index]);
          }
        } else {
          this.isFileMB = true;
          this.deleteDocument(this.fileId[index]);
          this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
            position: "top",
          });
        }
      });
      
    },
    async deleteDocument(Id) {
      this.$store.dispatch("loading/setIsLoading", true);
      try {
        await upload.fetchDeleteFile(Id);
        this.fileUploadData = this.fileUploadData.filter((f) => f.Id != Id);
        if (this.fileUploadData?.length == 0) {
          this.fileUploadData.push({
            Extension: "",
            Filename: "Doküman Yükle +",
            Id: 0,
          });
        }
        this.downloadFile = this.downloadFile.filter((f) => f.Id != Id);
        const uploadFile = this.$refs.fileUploadInput;
        uploadFile.value = null;
        this.fileId = this.fileId.filter((f) => f != Id);
      } catch (error) {
        //console.log("error->", error);
      }
      this.$store.dispatch("loading/setIsLoading", false);
    },
    suggestionResultSubmit() {
      if (this.suggestionApplicationStatus != "") {
        this.requiredField = [];
        if (
          !isNil(this.projectEndDate) ||
          !isNil(this.projectCancelledReason) ||
          !isNil(this.projectPendingReason)
        ) {
          const tmpFileId =
            this.fileId?.length > 0 ? this.fileId : this.detailData.FileIds;
          this.isBtnDisabled = true;
          const tmpData = {
            id: this.suggestionApplicationStatus,
            projectEndDate:
              this.projectEndDate != null
                ? new Date(this.projectEndDate)
                : null,
            projectCancelledReason: this.projectCancelledReason,
            projectCancelledDate:
              this.projectCancelledReason != null ? this.getCurrentDateTime() : null,
            projectPendingReason: this.projectPendingReason,
            pendingProcessDate:
              this.projectPendingReason != null ? this.getCurrentDateTime() : null,
            // aboutExpectedBenefit: this.aboutExpectedBenefit,
            // applicationCost: this.applicationCost,
            evaluatorFileIds: [...tmpFileId],
          };
          this.$emit("suggestion-result-submit", tmpData);
          this.isBtnDisabled = false;
        } else {
          if (
            this.suggestionApplicationStatus == "8" &&
            isNil(this.projectEndDate)
          )
            this.requiredField.push("projectEndDate");
          if (
            this.suggestionApplicationStatus == "10" &&
            isNil(this.projectCancelledReason)
          )
            this.requiredField.push("projectCancelledReason");
          if (
            this.suggestionApplicationStatus == "9" &&
            isNil(this.projectPendingReason)
          )
            this.requiredField.push("projectPendingReason");
        }
      }
    },
  },
};
</script>

<style></style>
