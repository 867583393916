import {
  suggestionService,
  suggestionInvendoService,
  suggestionRdService,
  upload,
} from "../../services";
import { updateDate } from "../../helpers";
import store from "../store";
export const draft = {
  namespaced: true,
  state: {
    ideaData: [],
    ideaTotalRecords: null,
    invendoData: [],
    invendoTotalRecords: null,
    productData: [],
    productTotalRecords: null,
  },
  getters: {
    getDraftIdeaData(state) {
      return state.ideaData;
    },
    getDraftIdeaTotalRecords(state) {
      return state.ideaTotalRecords;
    },
    getDraftInvendoData(state) {
      return state.invendoData;
    },
    getDraftInvendoTotalRecords(state) {
      return state.invendoTotalRecords;
    },
    getDraftProductData(state) {
      return state.productData;
    },
    getDraftProductTotalRecords(state) {
      return state.productTotalRecords;
    },
  },
  mutations: {
    SET_IDEA_DATA(state, payload) {
      state.ideaData = [];
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.ideaData.push(...payload);
    },
    SET_IDEA_DATA_TOTAL_RECORDS(state, payload) {
      state.ideaTotalRecords = payload;
    },
    SET_INVENDO_DATA(state, payload) {
      payload.forEach(async (element) => {
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
      });
      state.invendoData.push(...payload);
    },
    SET_INVENDO_DATA_TOTAL_RECORDS(state, payload) {
      state.invendoTotalRecords = payload;
    },
    SET_PRODUCT_DATA(state, payload) {
      state.productData = [];
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.productData.push(...payload);
    },
    SET_PRODUCT_DATA_TOTAL_RECORDS(state, payload) {
      state.productTotalRecords = payload;
    },
    DELETE_IDEA_DATA(state) {
      state.ideaData = [];
    },
    DELETE_INVENDO_DATA(state) {
      state.invendoData = [];
    },
    DELETE_PRODUCT_DATA(state) {
      state.productData = [];
    },
  },
  actions: {
    async setDraftIdeaData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        suggestionStatuses,
        createdUserId,
        loginUserId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("DELETE_IDEA_DATA");
      res.Data && vuexContext.commit("SET_IDEA_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_IDEA_DATA_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    async setDraftInvendoData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        suggestionStatuses,
        createdUserId,
        loginUserId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
      };
      const res = await suggestionInvendoService.fetchInvendoPagedSuggestion(
        body
      );
      res.Data && vuexContext.commit("DELETE_INVENDO_DATA");
      res.Data && vuexContext.commit("SET_INVENDO_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_INVENDO_DATA_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    async setDraftProductData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        suggestionStatuses,
        createdUserId,
        loginUserId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
      };
      const res = await suggestionRdService.fetchPagedSuggesitonRD(body);
      res.Data && vuexContext.commit("DELETE_PRODUCT_DATA");
      res.Data && vuexContext.commit("SET_PRODUCT_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_PRODUCT_DATA_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    deleteDraftIdeaData(vuexContext) {
      vuexContext.commit("DELETE_IDEA_DATA");
    },
    deleteDraftInvendoData(vuexContext) {
      vuexContext.commit("DELETE_INVENDO_DATA");
    },
    deleteDraftProductData(vuexContext) {
      vuexContext.commit("DELETE_PRODUCT_DATA");
    },
  },
};
